const config = {
    WS_BASE_URL: "https://pretty.deepakbaskota.org/v1/",
    DOMAIN_NAME: "https://admin.deepakbaskota.org/",
    IMAGE_URL: "https://pretty.deepakbaskota.org/v1/file",
    // WS_BASE_URL: "http://localhost:5000/v1/",
    // DOMAIN_NAME: "http://localhost:3001/",
    DEMO: true,
};

export default config;
