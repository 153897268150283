import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateDonation } from "network/ApiAxios";
import config from "../../config";

const UpdateDonationModal = ({ isOpen, toggleModal, data, setSuccess }) => {
    const handleSubmit = async (values) => {
        try {
            const response = await updateDonation(data?._id, values);
            console.log(response);
            if (response.status == 200) {
                setSuccess(true);
                toggleModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validationSchema = Yup.object({
        status: Yup.string().required("Status is required"),
    });

    const initialValues = {
        name: data?.name || "",
        email: data?.email || "",
        phone: data?.phone || "",
        transactionId: data?.transactionId || "",
        transactionImage: data?.transactionImage || "",
        status: data?.status || "",
    };

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Update Donation</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                    enableReinitialize
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    {touched.name && errors.name && (
                                        <div className="text-danger">{errors.name}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    {touched.email && errors.email && (
                                        <div className="text-danger">{errors.email}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="phone">Phone</Label>
                                    <Input
                                        type="text"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    {touched.phone && errors.phone && (
                                        <div className="text-danger">{errors.phone}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="transactionId">Transaction ID</Label>
                                    <Input
                                        type="text"
                                        name="transactionId"
                                        value={values.transactionId}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    {touched.transactionId && errors.transactionId && (
                                        <div className="text-danger">{errors.transactionId}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="transactionImage">Transaction Image</Label>
                                    <div>
                                        {values.transactionImage && (
                                            <img
                                                src={`${config.IMAGE_URL}/${values.transactionImage}`}
                                                alt="Transaction Image"
                                                style={{
                                                    width: "250px",
                                                    height: "250px",
                                                }}
                                            />
                                        )}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Input
                                        type="select"
                                        name="status"
                                        value={values.status}
                                        onChange={handleChange}
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="review">In Review</option>
                                        <option value="success">Success</option>
                                        <option value="failed">Failed</option>
                                    </Input>
                                    {touched.status && errors.status && (
                                        <div className="text-danger">{errors.status}</div>
                                    )}
                                </FormGroup>
                                <ModalFooter>
                                    <Button color="primary" type="submit">
                                        Update
                                    </Button>
                                    <Button color="secondary" onClick={toggleModal}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default UpdateDonationModal;
