import React, { useEffect, useState } from "react";
import Header from "../components/Headers/Header";
import AddEditDonation from "../components/Modals/AddEditDonation";
import { Button, Card, CardHeader, Container, Row, Table } from "reactstrap";
import { getAllDonations } from "../network/ApiAxios";
import { FaEdit, FaTrash } from "react-icons/fa";
import DeleteDonationModal from "components/Modals/DeleteDonationModal";
import Spinner from "components/utils/Spinner";
import config from "../config";

const DonationsTable = () => {
    const [donations, setDonations] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [isOpen, setIsOpen] = useState();
    const [data, setData] = useState();
    const [success, setSuccess] = useState();
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(null);

    const toggleDeleteModal = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleDelete = () => {
        // Refresh the donations list after deletion
        setSuccess(true);
    };

    useEffect(() => {
        const runAsync = async () => {
            setisLoading(true);
            const response = await getAllDonations();
            const { data } = response;
            setDonations(data);
            setisLoading(false);
        };
        runAsync();
    }, [success]);

    return (
        <>
            <Header />
            <Container>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex align-items-center">
                                <h3 className="mb-0">Donations</h3>
                                {/* <Button
                                    color="primary"
                                    outline
                                    className="ml-auto"
                                    onClick={() => {
                                        setData();
                                        toggleModal();
                                    }}
                                >
                                    Add Donation
                                </Button> */}
                            </CardHeader>
                            {isLoading ? (
                                <Spinner isLoading={isLoading} />
                            ) : (
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Transaction ID</th>
                                            <th scope="col">Status </th>
                                            <th scope="col">Transaction Image</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {donations.map((donation) => (
                                            <tr key={donation._id}>
                                                <th scope="row">{donation.name}</th>
                                                <td>{donation.email}</td>
                                                <td>{donation.phone}</td>
                                                <td>{donation.transactionId}</td>
                                                <td>{donation.status}</td>
                                                <td>
                                                    {donation.transactionImage ? (
                                                        <img
                                                            src={`${config.IMAGE_URL}/${donation.transactionImage}`}
                                                            alt="Transaction Image"
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                            }}
                                                        />
                                                    ) : (
                                                        "No Image"
                                                    )}
                                                </td>
                                                <td>
                                                    <Button color="secondary" outline>
                                                        <FaEdit
                                                            fontSize={18}
                                                            onClick={() => {
                                                                setData(donation);
                                                                setIsOpen(true);
                                                            }}
                                                        />
                                                    </Button>
                                                    <Button color="danger" outline>
                                                        <FaTrash
                                                            fontSize={18}
                                                            onClick={() => {
                                                                setDataToDelete(donation);
                                                                setIsOpenDeleteModal(true);
                                                            }}
                                                        />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </Card>
                    </div>
                </Row>
                <AddEditDonation
                    isOpen={isOpen}
                    toggleModal={toggleModal}
                    data={data}
                    setSuccess={setSuccess}
                />
                <DeleteDonationModal
                    isOpen={isOpenDeleteModal}
                    toggleModal={toggleDeleteModal}
                    data={dataToDelete}
                    onDelete={handleDelete}
                />
            </Container>
        </>
    );
};

export default DonationsTable;
