import React from "react";
import { MoonLoader } from "react-spinners";

export default function Spinner({ isLoading }) {
    const override = {
        display: "block",
        borderColor: "#299B79",
        margin: "calc(15vh) calc(50% - 100px)",
    };
    return (
        <MoonLoader
            color={"#299B79"}
            loading={isLoading}
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.4}
        />
    );
}
