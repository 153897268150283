import { deleteDonation } from "network/ApiAxios";
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const DeleteDonationModal = ({ isOpen, toggleModal, data, onDelete }) => {
    const handleDelete = async () => {
        try {
            const response = await deleteDonation(data._id);
            if (response.status === 200) {
                onDelete();
                toggleModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Delete Donation</ModalHeader>
            <ModalBody>Are you sure you want to delete this donation?</ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={handleDelete}>
                    Delete
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteDonationModal;
